import React, { useMemo, useState } from "react";
import {
  IconButton,
  Icon,
  makeStyles,
  Popover,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  List,
} from "@material-ui/core";
import {
  find,
  forEach,
  isArray,
  isEmpty,
  isNil,
  isObject,
  uniqBy,
} from "lodash";
import { useTranslation } from "react-i18next";

import { useMenuAnchor } from "../../../../core/hooks/useMenuAnchor";
import { useAccessesStore } from "../../../../core/stores/AccessesStore";
import { getLocaleText } from "../../../../util/UtilTraduction";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";

const useStyles = makeStyles(() => ({
  mainButton: {
    margin: 6,
  },
  listItemIcon: {
    minWidth: "auto",
    marginRight: 10,
  },
  itemsTitle: {
    padding: "10px 10px 0px 14px",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  listItemText: {
    marginRight: 10,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  groupTitle: {
    paddingInline: 16,
    paddingBlock: 6,
    fontWeight: "bold",
  },
  listItemContainer: {
    paddingLeft: 30,
    maxHeight: 35,
  },
  emptyDiv: {
    width: 32,
    maxHeight: 35,
  },
}));

const commonIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
};

function getItemsByParent(array1, array2, i18n) {
  // Create a mapping of parent[0] to items from array1
  const parentToItemsMap = {};

  forEach(array2, (item) => {
    if (item.parent && item.parent.length > 0) {
      const parent =
        !isNil(item?.parent?.[0]) && isObject(item?.parent?.[0])
          ? getLocaleText(item?.parent?.[0], i18n)
          : item?.parent?.[0];

      const matchingItem = find(array1, { id: item.id });
      if (matchingItem) {
        if (!parentToItemsMap[parent]) {
          parentToItemsMap[parent] = [];
        }
        parentToItemsMap[parent].push(matchingItem);
      }
    }
  });

  return parentToItemsMap;
}

export default function FavsButton({ handleProcessClick, isMobile }) {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [anchorEl, openMenuAnchor, closeMenuAnchor] = useMenuAnchor();
  const REQUEST_HEADERS = useRequestHeaders();
  const [isEditingFavs, setIsEditingFavs] = useState(false);

  const isUpdatingFavItems = useAccessesStore(
    (state) => state.isUpdatingFavItems
  );
  const favItems = useAccessesStore((state) => state.favItems);
  const callServerToDeleteFavItem = useAccessesStore(
    (state) => state.callServerToDeleteFavItem
  );
  const flattenedMenuItems = useAccessesStore(
    (state) => state.flattenedMenuItems
  );

  const open = Boolean(anchorEl);
  const id = open ? "favs_popover" : undefined;
  const totalNumber =
    !isNil(favItems) && !isEmpty(favItems) && isArray(favItems)
      ? favItems.length
      : 0;

  function handleItemClick(fav) {
    closeMenuAnchor();
    handleProcessClick(fav);
  }

  const favItemsGrouped = useMemo(() => {
    if (
      !isNil(favItems) &&
      !isEmpty(favItems) &&
      isArray(favItems) &&
      !isNil(flattenedMenuItems) &&
      !isEmpty(flattenedMenuItems) &&
      isArray(flattenedMenuItems)
    ) {
      const favItemsFiltered = uniqBy(favItems, "id");
      return getItemsByParent(favItemsFiltered, flattenedMenuItems, i18n);
    } else {
      const favItemsFiltered = uniqBy(favItems, "id");
      return { "": favItemsFiltered };
    }
  }, [favItems, flattenedMenuItems, i18n]);

  function toggleEditFavItems() {
    setIsEditingFavs((prev) => !prev);
  }

  return (
    <>
      <IconButton
        aria-label="favs_button"
        className={classes.mainButton}
        onClick={openMenuAnchor}
        disabled={isUpdatingFavItems}
      >
        <Icon className="fas fa-heart" style={commonIcon} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenuAnchor}
        anchorOrigin={{
          vertical: "center",
          horizontal: isMobile ? "left" : "right",
        }}
        transformOrigin={{
          vertical: isMobile ? "'top" : "center",
          horizontal: isMobile ? "right" : "left",
        }}
      >
        <div>
          <Typography variant="h6" className={classes.itemsTitle}>
            {t("FAVS_ITEMS_TITLE", { count: totalNumber })}
            {totalNumber > 0 && (
              <IconButton
                disabled={isUpdatingFavItems}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  toggleEditFavItems();
                }}
              >
                <Icon
                  className="fas fa-pen"
                  style={commonIcon}
                  fontSize="small"
                />
              </IconButton>
            )}
          </Typography>
          <List subheader={<li />}>
            {Object.keys(favItemsGrouped).map((group) => {
              return (
                <li key={`group-${group}`} className={classes.listSection}>
                  <ul className={classes.ul}>
                    {!isNil(group) && !isEmpty(group) && (
                      <Typography
                        className={classes.groupTitle}
                      >{`${group}`}</Typography>
                    )}
                    {uniqBy(favItemsGrouped[group], "id").map((fav) => {
                      return (
                        <ListItem
                          button
                          key={fav?.id}
                          onClick={() => handleItemClick(fav)}
                          className={classes.listItemContainer}
                        >
                          <ListItemIcon
                            classes={{ root: classes.listItemIcon }}
                          >
                            <Icon
                              className={fav?.name}
                              style={{ ...commonIcon, fontSize: 14 }}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={getLocaleText(fav?.propText, i18n)}
                            className={classes.listItemText}
                          />
                          {isEditingFavs ? (
                            <ListItemSecondaryAction>
                              <IconButton
                                disabled={isUpdatingFavItems}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  callServerToDeleteFavItem({
                                    REQUEST_HEADERS,
                                    id: fav?.id,
                                  });
                                }}
                              >
                                <Icon
                                  className="fas fa-trash-alt"
                                  style={{ ...commonIcon, fontSize: 11 }}
                                />
                              </IconButton>
                            </ListItemSecondaryAction>
                          ) : (
                            <div className={classes.emptyDiv} />
                          )}
                        </ListItem>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </List>
        </div>
      </Popover>
    </>
  );
}
