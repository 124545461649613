import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  MenuItem,
  Paper,
  TextField,
  Typography,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { INITIAL_DIALOG_CONFIRM_QUERY_ACTION } from "../table/Table";
import {
  isNil,
  isArray,
  size,
  toNumber,
  trim,
  startsWith,
  toString,
  isEmpty,
  filter,
  includes,
} from "lodash";
import Draggable from "react-draggable";
import {
  HTTP_STATUS_UNAUTHORIZED,
  METHOD_POST,
  SERVER_HOST,
  TOAST_CONTAINER_LAYOUT,
} from "../../../util/Constants";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { toast } from "react-toastify";
import { AuthContext } from "../../../core/providers/AuthContext";
import { getMinimunMultiple } from "../../dashboards/friday/util-friday";

function DraggablePaperComponent(props) {
  return (
    <Draggable handle="#confirmation-query-action-dialog-title">
      <Paper {...props} />
    </Draggable>
  );
}

const commonStatusIcon = {
  width: "auto",
  height: "auto",
  padding: 1,
  marginRight: 5,
  fontSize: 16,
};

const useStyles = makeStyles(() => ({
  dialogTitle: {
    fontSize: "18px",
    padding: "16px",
    marginBlock: "12px",
  },
  dialogContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column-reverse",
    gap: 12,
  },
  dialogContentLoading: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 12,
  },
  statusTypo: {
    display: "flex",
    alignItems: "center",
    marginBlock: "6px",
  },
  fieldsContainer: {
    display: "flex",
    width: "100%",
    gap: 10,
    flexDirection: "column",
  },
  textfieldWithFunction: {
    display: "flex",
    flexDirection: "column",
  },
}));

const INITIAL_EXECUTION_SUMMARY = {
  ok: 0,
  error: 0,
};

const KNOWN_FUNCTIONS = ["um"];

export async function invoqueToGetFields({ REQUEST_HEADERS, body, url }) {
  const response = await fetch(url, {
    method: METHOD_POST,
    headers: REQUEST_HEADERS,
    body: JSON.stringify(body),
  })
    .then(async (res) => {
      const data = await res.json();
      if (!isNil(data) && !isEmpty(data)) {
        return { status: res.status, ...data };
      } else {
        return { status: res.status };
      }
    })
    .catch((error) => {
      return { ok: false, error: error.message };
    });

  return response;
}

function resolveFunctionWithParams({
  value,
  applyFunction,
  paramsFunction,
  rowValues,
}) {
  if (applyFunction === "um") {
    return getMinimunMultiple(
      toNumber(value),
      rowValues?.[paramsFunction?.["unitMeasure"]]
    );
  }
}

function replaceFunctionValues(formState) {
  if (!isNil(formState) && !isEmpty(formState)) {
    let result = {};
    for (let key in formState) {
      if (key.includes("@@")) {
        let baseKey = key.split("@@")[0];
        result[baseKey] = formState[key];
      } else {
        result[key] = formState[key];
      }
    }

    return result;
  } else {
    return formState;
  }
}

export default function ConfirmQueryActionDialog({
  executeQueryAction,
  setConfirmQueryActionDialog,
  confirmQueryActionDialog,
  closeAndRefresh,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const muiTheme = useTheme();
  const [fecthingFields, setFetchingFields] = useState(true);
  const [fields, setFields] = useState([]);
  const [formState, setFormState] = useState(
    fields.reduce((acc, { field }) => ({ ...acc, [field]: "" }), {})
  );
  const REQUEST_HEADERS = useRequestHeaders();
  const { logout } = useContext(AuthContext);

  const endpointGetFields =
    confirmQueryActionDialog?.params?.endpointGetConfirmationFields;

  const rowValues = confirmQueryActionDialog?.params?.row || {};

  // Multiple execution state
  const [multipleExecutionCounter, setMultipleExecutionCounter] = useState(0);
  const [isExecutingMultiple, setIsExecutingMultiple] = useState(false);
  const [multipleExecutionSummary, setMultipleExecutionSummary] = useState(
    INITIAL_EXECUTION_SUMMARY
  );
  const [multipleExectionFinished, setMultipleExectionFinished] =
    useState(false);

  useEffect(() => {
    setIsExecutingMultiple(false);
    setMultipleExecutionCounter(0);
    setMultipleExecutionSummary(INITIAL_EXECUTION_SUMMARY);
    setMultipleExectionFinished(false);
    setFetchingFields(true);
    setFields([]);
  }, [confirmQueryActionDialog]);

  useEffect(() => {
    if (!isNil(endpointGetFields) && trim(endpointGetFields) !== "") {
      const fetchFields = async () => {
        const endpointToUse = startsWith(endpointGetFields, "/")
          ? toString(endpointGetFields).substring(1)
          : endpointGetFields;
        const url = `${SERVER_HOST()}form/api/${endpointToUse}`;
        const response = await invoqueToGetFields({
          REQUEST_HEADERS,
          url,
          body: rowValues,
        });
        return response;
      };

      fetchFields().then((res) => {
        if (!isNil(res)) {
          const { errorMsg, ok, status, dataResponse } = res;
          if (status === HTTP_STATUS_UNAUTHORIZED) {
            logout(true);
          } else if (ok && dataResponse) {
            setFields(dataResponse);
          } else if (!ok) {
            toast.error(errorMsg || t("FORM_ERROR_ACTION"), {
              containerId: TOAST_CONTAINER_LAYOUT,
            });
          }
        } else {
          toast.error(t("ERROR_RESOURCE_NOT_FOUND_TEXT"), {
            containerId: TOAST_CONTAINER_LAYOUT,
          });
        }
        setFetchingFields(false);
      });
    } else {
      setFetchingFields(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmQueryActionDialog]);

  const disabledByRequiredFields = useMemo(() => {
    if (
      !isNil(fields) &&
      !isNil(formState) &&
      isArray(fields) &&
      !isEmpty(fields)
    ) {
      const requiredFields = filter(fields, function (o) {
        return !isNil(o) && o.required === true;
      });
      if (!isNil(requiredFields) && !isEmpty(requiredFields)) {
        let innerNotFilled = false;
        for (const rf of requiredFields) {
          if (isNil(formState?.[rf?.field])) {
            innerNotFilled = true;
            break;
          }
        }
        return innerNotFilled;
      } else {
        return false;
      }
    }
    return false;
  }, [fields, formState]);

  const showCondition =
    !isNil(confirmQueryActionDialog) &&
    !isNil(confirmQueryActionDialog.params) &&
    confirmQueryActionDialog?.open === true;

  const isMultipleExecution =
    !isNil(confirmQueryActionDialog) &&
    !isNil(confirmQueryActionDialog.params) &&
    confirmQueryActionDialog?.params?.multipleExecution === true;

  if (!showCondition) {
    return null;
  }

  const totalRows = isMultipleExecution
    ? size(confirmQueryActionDialog?.params?.selectedFlatRows)
    : 0;

  async function handleExecuteClick() {
    const formValuesToUse = replaceFunctionValues(formState);
    if (isMultipleExecution) {
      const selectedRowsToIterate =
        confirmQueryActionDialog?.params?.selectedFlatRows;
      if (!isNil(selectedRowsToIterate) && isArray(selectedRowsToIterate)) {
        let okFlag = 0;
        let errorFlag = 0;
        for (const selRow of selectedRowsToIterate) {
          if (!isNil(selRow)) {
            const { original, id } = selRow;
            const rowResponse = await executeQueryAction({
              ...confirmQueryActionDialog.params,
              hideQueryActionLoaderModal: true,
              row: original,
              line: toNumber(id) + 1,
              confirmFields: formValuesToUse,
            });
            if (!isNil(rowResponse)) {
              const { ok } = rowResponse;
              if (ok === true) {
                okFlag++;
              } else {
                errorFlag++;
              }
            } else {
              errorFlag++;
            }
            setMultipleExecutionCounter((prev) => prev + 1);
          }
        }
        setMultipleExecutionSummary({ ok: okFlag, error: errorFlag });
      }
    } else {
      executeQueryAction({
        ...confirmQueryActionDialog?.params,
        confirmFields: formValuesToUse,
      });
      setConfirmQueryActionDialog(INITIAL_DIALOG_CONFIRM_QUERY_ACTION);
    }

    setTimeout(() => {
      setIsExecutingMultiple(false);
      setMultipleExectionFinished(true);
    }, [100]);
  }

  const handleChange = (event, applyFunction, paramsFunction) => {
    const { name, value } = event.target;

    if (!isNil(applyFunction)) {
      const transformedValue = resolveFunctionWithParams({
        value,
        applyFunction,
        paramsFunction,
        rowValues,
      });

      setFormState({
        ...formState,
        [name]: value,
        [`${name}@@${applyFunction}`]: transformedValue,
      });
    } else {
      setFormState({
        ...formState,
        [name]: value,
      });
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      aria-labelledby="confirmation-query-action-dialog-title"
      open={confirmQueryActionDialog?.open}
      PaperComponent={DraggablePaperComponent}
    >
      <Typography
        id="confirmation-query-action-dialog-title"
        className={classes.dialogTitle}
        style={{ cursor: "move" }}
      >
        {confirmQueryActionDialog?.title}
      </Typography>
      {fecthingFields ? (
        <DialogContent className={classes.dialogContentLoading}>
          <CircularProgress />
        </DialogContent>
      ) : (
        <DialogContent className={classes.dialogContent}>
          {isExecutingMultiple && (
            <>
              <CircularProgress size={20} />
              <Typography>
                {multipleExecutionCounter > 0
                  ? `${t(
                      "USER_SUBSTITUTIONS_COL_STATUS"
                    )} ${multipleExecutionCounter}/${totalRows}`
                  : ""}
              </Typography>
            </>
          )}
          {multipleExectionFinished && isExecutingMultiple === false && (
            <div>
              <Typography variant="h5">
                {t("REPORTS_QUERY_ACTION_MULTIPLE_ROWS_SUMMARY", {
                  rows: size(
                    confirmQueryActionDialog?.params?.selectedFlatRows
                  ),
                })}
              </Typography>
              <Typography variant="body1" className={classes.statusTypo}>
                <Icon
                  className="fas fa-check"
                  style={{
                    ...commonStatusIcon,
                    color: muiTheme.palette.semaphore.green,
                  }}
                />
                {t("REPORTS_QUERY_ACTION_MULTIPLE_ROWS_OK", {
                  rows: multipleExecutionSummary.ok,
                })}
              </Typography>
              <Typography variant="body1" className={classes.statusTypo}>
                <Icon
                  className="fas fa-times"
                  style={{
                    ...commonStatusIcon,
                    marginRight: 9,
                    color: muiTheme.palette.semaphore.red,
                  }}
                />
                {t("REPORTS_QUERY_ACTION_MULTIPLE_ROWS_ERROR", {
                  rows: multipleExecutionSummary.error,
                })}
              </Typography>
            </div>
          )}
          {!isNil(fields) && isArray(fields) && !isEmpty(fields) ? (
            <div className={classes.fieldsContainer}>
              {fields.map(
                ({
                  field,
                  label,
                  options,
                  required,
                  applyFunction,
                  labelFunction,
                  paramsFunction,
                  maxLength,
                }) => {
                  const isRequired = required === true;
                  if (
                    !isNil(options) &&
                    isArray(options) &&
                    !isEmpty(options)
                  ) {
                    return (
                      <>
                        <TextField
                          key={field}
                          name={field}
                          label={isRequired ? `${label} *` : label}
                          value={formState[field] || ""}
                          onChange={(e) => handleChange(e)}
                          variant="outlined"
                          select={true}
                          disabled={multipleExectionFinished}
                        >
                          {options.map((option) => {
                            const { code, label } = option;
                            return (
                              <MenuItem key={code} value={code}>
                                {label}
                              </MenuItem>
                            );
                          })}
                        </TextField>
                      </>
                    );
                  } else {
                    if (
                      !isNil(applyFunction) &&
                      includes(KNOWN_FUNCTIONS, applyFunction)
                    ) {
                      return (
                        <div className={classes.textfieldWithFunction}>
                          <TextField
                            key={field}
                            name={field}
                            label={isRequired ? `${label} *` : label}
                            value={formState[field] || ""}
                            onChange={(e) =>
                              handleChange(e, applyFunction, paramsFunction)
                            }
                            variant="outlined"
                            multiline
                            maxRows={3}
                            disabled={multipleExectionFinished}
                            inputProps={{ maxLength: maxLength || null }}
                          />
                          <TextField
                            key={`${field}@@${applyFunction}`}
                            name={`${field}@@${applyFunction}`}
                            value={
                              formState[`${field}@@${applyFunction}`] || ""
                            }
                            label={labelFunction || ""}
                            multiline
                            maxRows={3}
                            disabled={true}
                            margin="dense"
                          />
                        </div>
                      );
                    } else {
                      return (
                        <TextField
                          key={field}
                          name={field}
                          label={isRequired ? `${label} *` : label}
                          value={formState[field] || ""}
                          onChange={(e) => handleChange(e)}
                          variant="outlined"
                          multiline
                          maxRows={3}
                          disabled={multipleExectionFinished}
                          inputProps={{ maxLength: maxLength || null }}
                        />
                      );
                    }
                  }
                }
              )}
            </div>
          ) : null}
        </DialogContent>
      )}

      <DialogActions>
        {multipleExectionFinished === false ? (
          <>
            <Button
              onClick={() => {
                setIsExecutingMultiple(true);
                setMultipleExecutionCounter(0);
                handleExecuteClick();
              }}
              color="primary"
              disabled={
                isExecutingMultiple ||
                fecthingFields ||
                disabledByRequiredFields
              }
            >
              {t("TABLE_PANEL_DECISION_EXE")}{" "}
              {size(confirmQueryActionDialog?.params?.selectedFlatRows) > 1 &&
                t("SELECTED_ROWS", {
                  rows: size(
                    confirmQueryActionDialog?.params?.selectedFlatRows
                  ),
                })}
            </Button>
            <Button
              onClick={() =>
                setConfirmQueryActionDialog(INITIAL_DIALOG_CONFIRM_QUERY_ACTION)
              }
              disabled={isExecutingMultiple || fecthingFields}
            >
              {t("FORM_CANCEL")}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => closeAndRefresh()}
            disabled={isExecutingMultiple || fecthingFields}
          >
            {t("CLOSE")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
