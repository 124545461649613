const EMPTY_STRING_FOR_MSG = " ";

export const AVAILABLE_FORMATS = {
  //IMAGE
  images: {
    desc: ["png", "jpg", "jpeg", "gif"],
  },
  png: {
    full: false,
    desc: "png",
    canPreview: true,
    mimeType: "image/png",
  },
  jpg: {
    full: false,
    desc: "jpg",
    canPreview: true,
    mimeType: "image/jpg, image/vnd.sealedmedia.softseal.jpg, image/jpeg",
  },
  jpeg: {
    full: false,
    desc: "jpeg",
    canPreview: true,
    mimeType: "image/jpg, video/jpeg",
  },
  gif: {
    full: false,
    desc: "gif",
    canPreview: true,
    mimeType: "image/gif, video/jpeg",
  },
  // svg: {
  //   full: false,
  //   desc: "svg",
  //   canPreview: false,
  //   mimeType: "image/svg+xml, application/vnd.oipf.dae.svg+xml",
  // },
  //DOCUMENTS
  txt: {
    full: true,
    desc: "txt",
    canPreview: false,
    mimeType: "text/plain",
  },
  pdf: {
    full: true,
    desc: "pdf",
    canPreview: true,
    mimeType: "application/pdf",
  },
  csv: {
    full: true,
    desc: "csv",
    canPreview: false,
    mimeType: "text/csv",
  },
  docx: {
    full: true,
    desc: "docx",
    canPreview: true,
    mimeType:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  doc: {
    full: true,
    desc: "doc",
    canPreview: true,
    mimeType: "application/msword",
  },
  xlsx: {
    full: true,
    desc: "xlsx",
    canPreview: true,
    mimeType:
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  },
  xls: {
    full: false,
    desc: "xls",
    canPreview: true,
    mimeType: "application/vnd.ms-excel",
  },
  ppt: {
    full: true,
    desc: "ppt",
    canPreview: true,
    mimeType: "application/vnd.ms-powerpoint",
  },
  pptx: {
    full: true,
    desc: "pptx",
    canPreview: true,
    mimeType:
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  },
  //VIDEO AND AUDIO
  mp3: {
    full: false,
    desc: "mp3",
    canPreview: true,
    mimeType: "audio/mpeg",
  },
  mpeg: {
    full: false,
    desc: "mpeg",
    canPreview: false,
    mimeType: "video/mpeg",
  },
  mp4: {
    full: false,
    desc: "mp4",
    canPreview: true,
    mimeType: "video/mp4",
  },
  avi: {
    full: false,
    desc: "avi",
    canPreview: false,
    mimeType: "video/x-msvideo",
  },
  mov: {
    full: false,
    desc: "mov",
    canPreview: false,
    mimeType: "video/quicktime",
  },
  eml: {
    full: false,
    desc: "eml",
    canPreview: false,
    mimeType: "message/rfc822",
  },
  msg: {
    full: false,
    desc: "msg",
    canPreview: false,
    mimeType: `application/vnd.ms-office, application/vnd.ms-outlook,${EMPTY_STRING_FOR_MSG}`,
  },
};

export const MIME_TYPES_ALLOWED = Object.values(AVAILABLE_FORMATS).flatMap(
  (format) => {
    if (format.mimeType) {
      return format.mimeType.split(", ");
    } else {
      return [];
    }
  }
);

export const AWS_S3_URL_FILE_ON_DEMAND = "ON-DEMAND";
